<template>
  <div class="box_mileage">
    <div class="tit_mileage">
      <span class="ico_account" @click.prevent="backdoorLogin" />사원 마일리지
    </div>
    <div class="info_mileage">
      <span class="txt_current">{{ remainPoint | currency }}</span>
      <span class="txt_bar">/</span>
      <span class="txt_all">{{ basicPoint | currency }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

export default {
  name: "GnbExtendBottom",
  mixins: [CommLayerMixin],
  data() {
    return {
      remainPoint: 0,
      basicPoint: 0,
    };
  },
  created() {
    axios
      .post("/api/selectRemainPoint")
      .then((res) => {
        this.remainPoint = res.data.remainPoint;
        this.basicPoint = res.data.basicPoint;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    backdoorLogin() {
      if (
        window.location.origin === "https://demand.kkep.io" ||
        window.location.origin === "http://localhost"
      ) {
        let layer = {};
        layer.type = "popup";
        layer.class = "";
        layer.header = "우회 로그인";
        layer.componentFile = "common/CommBackdoor.vue";
        this._showLayer(layer);
      }
    },
  },
};
</script>
<style scoped>
.box_mileage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 149px;
  padding: 31px 0 0 30px;
  box-sizing: border-box;
} /* 2019-10-15 수정 */
.box_mileage .tit_mileage {
  font-size: 13px;
  line-height: 18px;
  color: #777;
}
.box_mileage .tit_mileage .ico_account {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  background-position: -160px -20px;
  vertical-align: top;
}
.box_mileage .info_mileage {
  padding-top: 7px;
  font-size: 0;
  line-height: 0;
  color: #999;
}
.box_mileage .info_mileage [class*="txt_"] {
  font-family: "Helvetica", sans-serif;
  line-height: 24px;
}
.box_mileage .txt_current {
  font-size: 22px;
  color: #1a1a1a;
}
.box_mileage .txt_bar {
  font-size: 14px;
  margin: 0 6px 0 5px;
}
.box_mileage .txt_all {
  font-size: 14px;
}
</style>
